@import './reset.scss';

body,
html {
  font-family: 'futura-pt', sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
.h1,
h2,
.h2 {
  color: #171b3d;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 24px;
  line-height: 32px;
}

h2,
.h2 {
  font-size: 16px;
  line-height: 24px;
}

.text-14,
p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.text-14_m {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.text-16_m {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.link {
  color: #171b3d;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.25s ease;

  path {
    transition: all 0.25s ease;
  }

  &:hover {
    color: #006cb1;
  }

  &:active {
    color: mix(rgb(23, 27, 61), #006cb1, 24%);

    path {
      fill: mix(rgb(23, 27, 61), #006cb1, 24%);
    }
  }
}

.relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}
