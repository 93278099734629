.map-container {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - 800px);
  height: 100vh;
}

.custom-zoom-control__sub-container {
  padding: 10px 8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(23, 27, 61, 0.08);
}

.custom-zoom-control {
  position: absolute;
  top: 50%;
  right: 24px;
  z-index: 10;
  transform: translateY(-50%);

  .zoom-in {
    margin-bottom: 16px;
  }

  .zoom-out,
  .zoom-in {
    display: block;
    width: 16px;
    height: 16px;
  }
}

.custom-zoom-control__button {
  path {
    transition: all 0.25s ease;
  }

  &:not(:hover) {
    &:active {
      path {
        fill: linear-gradient(0deg, rgba(23, 27, 61, 0.24), rgba(23, 27, 61, 0.24)), #006cb1;
        fill-opacity: 1;
      }
    }
  }

  &:hover {
    path {
      fill: #006cb1;
      fill-opacity: 1;
    }
  }
}

.custom-zoom-control__line {
  position: absolute;
  top: 50%;
  right: 16px;
  left: 16px;
  height: 1px;
  background: rgba(15, 46, 74, 0.2);
  border-radius: 20px;
  transform: translateY(-50%);
}

.set-center-button {
  margin-top: 16px;
  padding: 8px;
  font-size: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(23, 27, 61, 0.08);

  path {
    transition: all 0.25s ease;
  }

  &:hover,
  &.active {
    path {
      fill: #006cb1;
      fill-opacity: 1;
    }
  }
}

div.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: rgba(13, 26, 51, 0.9);
  backdrop-filter: blur(8px);
}

div.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: rgba(13, 26, 51, 0.9);
  backdrop-filter: blur(8px);
}

div.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
div.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
div.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: rgba(13, 26, 51, 0.9);
  backdrop-filter: blur(8px);
}

div.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
div.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
div.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: rgba(13, 26, 51, 0.9);
  backdrop-filter: blur(8px);
}

div.mapboxgl-popup-content {
  padding: 20px;
  background-color: rgba(13, 26, 51, 0.9);
  border-radius: 2px;
  backdrop-filter: blur(8px);
}

.popup-title {
  font-weight: 500;
}

.popup-title,
.popup-sub-title {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.popup-top-row {
  position: relative;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(206, 217, 245, 0.1);
  border-radius: 2px;
}

.popup-table-data {
  text-align: right;
}

.popup-table-row {
  &:not(:last-child) {
    position: relative;
    border-bottom: 1px solid rgba(206, 217, 245, 0.1);
    border-radius: 2px;

    td {
      padding: 20px 0;
    }
  }
}

.popup-table-row td {
  padding-top: 20px;

  &.popup-table-col {
    padding-right: 48px;
  }
}

.popup-val {
  display: block;

  &:not(:last-child) {
    padding-bottom: 6px;
  }
}

.popup-row {
  &:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(206, 217, 245, 0.1);
  }
}

.popup-val-dem {
  padding-right: 14px;
  color: rgba(206, 217, 245, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.popup-row__sub-row {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    padding-bottom: 6px;
  }
}

.popup-col {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  &:not(:last-child) {
    margin-right: 48px;
  }
}

.popup-col__cell {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

div.mapboxgl-map {
  font: inherit;
}

.show-gradient-container {
  position: relative;
}

.show-gradient {
  position: absolute;
  top: 0;
  right: 50px;
  width: 180px;
  padding: 20px 24px 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(23, 27, 61, 0.08);

  .task-settings-row.checkbox-row {
    margin-top: 0;

    &:not(:last-child),
    &:last-child {
      margin-top: 0;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.show-gradient__title {
  margin-bottom: 16px;
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}

.show-gradient__triangle {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: -30px;
    width: 20px;
    height: 21px;
    background-color: #fff;
    border-radius: 2px;
    transform: rotate(45deg);
    content: '';
  }
}

.gradient {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 99999;
  box-shadow: 0 8px 24px rgba(23, 27, 61, 0.08);
}

.gradient__container {
  width: 405px;
  padding-top: 12px;
  background: #fff;
  border-radius: 4px;
}

.gradient__title {
  position: relative;
  color: rgba(15, 46, 74, 0.4);
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.gradient__dots {
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  border-radius: 50%;
  filter: drop-shadow(0 0 4px rgba(23, 27, 61, 0.24));
}

.gradient__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 376px;
  height: 8px;
  margin: 0 auto;
  background: linear-gradient(90deg, #29af62 0%, #ffba10 53.13%, #ff1053 100%);
  border-radius: 8px;
}

.gradient__values {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px 8px 12px;
}

.gradient__type {
  position: absolute;
  top: 13px;
  left: -33px;
  z-index: -1;
  width: 48px;
  padding-bottom: 2px;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: rgba(159, 171, 183, 0.32);
  border-radius: 4px;
  transform: rotate(-90deg);
  backdrop-filter: blur(8px);
}
