.error-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f3f4f8;
}

.error-page-top-row {
  position: relative;
  z-index: 1;
  align-items: center;
  height: 72px;
  padding: 24px 32px 24px 24px;
}

.error-page-content {
  position: absolute;
  top: 37%;
  left: 50%;
  -webkit-transform: translate(-50%, -37%);
  transform: translate(-50%, -37%);
}

.error-page-img {
  width: 510px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
}

.not-found-error {
  background-image: url(../assets/images/404.svg);
}

.internal-server-error {
  background-image: url(../assets/images/500.svg);
}

.error-page-general-text {
  margin-top: 40px;
  color: #0f2e4a;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}

.error-page-detailed-text {
  margin-top: 8px;
  color: #0f2e4a;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}

.error-page-button-wrapper {
  margin-top: 40px;
  text-align: center;
}

.error-page-button {
  border-radius: 54px;
}
