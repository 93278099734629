.login-page-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f3f4f8;
}

.login-page-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 328px;
  height: 330px;
  padding: 32px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 16px 24px rgba(23, 27, 61, 0.08);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-page-logo-wrapper {
  display: inline-block;
  height: 73px;
  margin-bottom: 32px;
  padding: 0 0 33px 0;
  border-bottom: 1px solid #e9eaee;
}

.logo-img-wrapper {
  float: left;
  width: 93px;
  padding-right: 16px;
}

.login-page-input {
  width: 100%;
  height: 40px;
  padding: 10px 16px;
  color: #0f2e4a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: #f3f4f8;
  border: none;
  border-radius: 2px;
}

.login-page-input.password-input {
  font: small Verdana, sans-serif;
  letter-spacing: 2px;
}

.login-page-input::placeholder {
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 14px;
  font-family: 'futura-pt', sans-serif;
  line-height: 20px;
  letter-spacing: normal;
}

.login-page-input-container.password {
  margin-top: 16px;
}

.login-page-input-container.error .login-page-input {
  color: #f0115e;
  -webkit-box-shadow: inset 0 0 0 1px #f0115e;
  -moz-box-shadow: inset 0 0 0 1px #f0115e;
  box-shadow: inset 0 0 0 1px #f0115e;
}

.login-page-input-container.error .login-page-input::placeholder {
  color: rgba(240, 17, 94, 0.4);
}

.login-tooltip {
  position: absolute;
  top: 44px;
  left: 0;
  height: 36px;
  padding: 8px 12px;
  background-color: rgba(13, 26, 51, 0.9);
  border-radius: 2px;
  opacity: 0;
}

.login-tooltip.not-exist-tooltip {
  width: 252px;
  height: 56px;
  text-align: left;
}

.login-tooltip-text {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.login-page-input-container.error .login-page-input:hover ~ .login-tooltip {
  z-index: 5;
  opacity: 1;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
}

.login-page-button-wrapper {
  width: 100%;
  margin-top: 24px;
  text-align: center;
}

.login-page-button {
  width: 100%;
}

.logo-text-wrapper {
  display: inline-block;
  width: 160px;
  height: 32px;
  margin-top: 8px;
  padding-left: 16px;
  text-align: left;
  border-left: 1px solid #006cb1;
}

.logo-text {
  color: #006cb1;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
