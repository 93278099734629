.user-logo {
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  background-image: url(../../assets/images/user.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.user-logo-popup-menu {
  position: fixed;
  top: 63px;
  right: 24px;
  z-index: 9999;
  width: 90px;
  height: 56px;
  padding: 16px 24px;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  background: #fff;
  box-shadow: 0 8px 24px rgba(23, 27, 61, 0.08);
}
