.network-container {
  padding: 0 32px 20px;
}

.network-top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px 24px;
}

.network-dashboard {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px 16px 0;
  background: #f3f4f8;
  border-radius: 2px;

  &.small {
    padding: 12px 16px 0;
  }
}

.network-dashboard__item {
  margin-bottom: 16px;
  padding: 24px 24px 16px 24px;
  background: linear-gradient(0deg, #fff, #fff), #f3f4f8;
  border-radius: 2px;

  &.big {
    width: 224px;
  }

  &.small {
    width: 128px;
    padding: 20px;
  }
}

.index {
  position: absolute;
  top: -5px;
  right: -6px;
  font-size: 10px;
}

.sub-index-container {
  margin-right: 2px;
  padding-right: 1px;

  .sub-index {
    right: -5px;
  }
}

.sub-index {
  position: absolute;
  right: -6px;
  bottom: -5px;
  font-size: 10px;
}

.network-dashboard__item-dem {
  margin-bottom: 8px;
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.network-dashboard__item-value {
  color: #0f2e4a;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.network-dashboard__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.network-dashboard__item-diff {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  &.red {
    color: #f0115e;
  }

  &.green {
    color: #29af62;
  }
}

.small {
  .network-dashboard__item-dem {
    margin-bottom: 4px;
  }

  .network-dashboard__item-value {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}

.comparation {
  background: #f3f4f8;
  border-radius: 2px;
  cursor: pointer;

  &:not(.active) {
    padding-bottom: 12px;
  }
}

.comparation-title {
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.comparation__top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 0 16px;
}

.comparation-text {
  padding: 0 16px;
  color: #0f2e4a;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.active {
  .comparation-arrow {
    transform: rotate(0);
  }
}

.comparation-arrow {
  transform: rotate(-90deg);
}

.network-tables {
  margin-top: 24px;
}

.react-tabs__tab-list {
  display: flex;
  border-bottom: 1px solid #e9eaee;
}

.react-tabs__tab {
  position: relative;
  margin-right: 40px;
  padding-bottom: 12px;
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.25s ease;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: transparent;
    transition: all 0.25s ease;
    content: '';
  }

  &--selected {
    color: #006cb1;

    &::after {
      background-color: #006cb1;
    }
  }
}

.network-tables__container {
  padding-top: 24px;
}

.table {
  width: 100%;
}

.node {
  .table-data,
  .table-head__cell {
    &:nth-child(n + 2) {
      text-align: right;
    }
  }
}

.table-head__cell {
  padding: 10px;
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  background: #f3f4f8;

  &:nth-child(n + 3) {
    text-align: right;
  }

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.table-data {
  padding: 10px;
  color: #0f2e4a;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &:nth-child(n + 3) {
    text-align: right;
  }
}

.table-row {
  border-bottom: 1px solid #e9eaee;
  cursor: pointer;
  transition: all 0.25s ease;

  &.active-hover,
  &:hover {
    background: rgba($color: #f3f4f8, $alpha: 0.6);
  }

  &.active {
    background: rgba(225, 237, 250, 0.4);
  }
}

.MuiSkeleton-root.skeleton-root {
  display: inline-block;
  background-color: #f3f4f8;
  border-radius: 2px;
  opacity: 0.7;
}

.dashboard-skeleton {
  width: 72px;
  height: 24px;
}

.table-skeleton-56 {
  width: 56px;
  height: 20px;
}

.table-skeleton-72 {
  width: 72px;
  height: 20px;
}

.table-skeleton-24 {
  width: 24px;
  height: 20px;
}

.MuiSkeleton-pulse.skeleton-root {
  animation: skeleton-keyframes-pulse 0.75s ease-in infinite;
}

//noinspection Stylelint
@keyframes skeleton-keyframes-pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.loading-panel-container {
  position: fixed;
  top: 50%;
  right: calc((100vw - 800px) / 2);
  z-index: 10;
  width: 480px;
  height: 84px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  transform: translate(50%, -50%);
}

.loading-panel-content {
  padding-top: 32px;
  text-align: center;
}

.loading-panel-content-text {
  position: relative;
  color: #0f2e4a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.loading-panel-img {
  position: absolute;
  top: 1px;
  left: -24px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url(../assets/images/executionButton.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: spin 4s linear infinite;
}

.network-not-found-container {
  position: relative;
  height: calc(100vh - 72px);
}

.network-not-found-content {
  position: absolute;
  top: 42%;
  left: 50%;
  -webkit-transform: translate(-50%, -42%);
  transform: translate(-50%, -42%);
}

.network-not-found-img {
  width: 572px;
  height: 197px;
  background-image: url(../assets/images/404-network.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.network-not-found-general-text {
  margin-top: 40px;
  color: #0f2e4a;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
}

.network-not-found-detailed-text {
  margin-top: 8px;
  color: #0f2e4a;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}

.network-not-found-button-wrapper {
  margin-top: 40px;
  text-align: center;
}

.network-not-found-button {
  border-radius: 54px;
}

@media (max-width: 1420px) {
  .loading-panel-container {
    width: 424px;
  }
}
