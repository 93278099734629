.input {
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  color: #0f2e4a;
  font: normal 14px 'futura-pt', sans-serif;
  line-height: 20px;
  border: 1px solid #dfe0e4;
  border-radius: 2px;
  transition: all 0.25s ease;

  &:hover {
    border: 1px solid #bfc0c9;
  }

  &.error {
    color: #f0115e;
    border: 1px solid #f0115e;
  }
}
