.calc-settings-dash {
  margin-right: 16px;
}

.gathering-center-row.calc-settings-select-row {
  height: 84px;
  border-bottom: solid 1px #dfe0e4;
}

.calc-settings-radio-row .task-settings-row:first-child {
  margin-top: 16px;
}
