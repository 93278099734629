.dialog-window {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(42, 47, 59, 0.32);
}

.dialog-window-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 656px;
  height: 360px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 16px 32px rgba(74, 83, 114, 0.08);
  transform: translate(-50%, -50%);
}

.dialog-window-content-container {
  padding: 32px;
}

.dialog-window-close-button {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }

  & svg path {
    opacity: 0.4;
  }

  &:hover svg path {
    opacity: 0.48;
  }

  &:active svg path {
    opacity: 0.64;
  }
}

.dialog-window-title {
  margin-bottom: 20px;
  color: #2a2f3b;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.dialog-window-button-wrapper {
  width: 100%;
  padding: 32px;
  background: #f3f4f8;
  border-radius: 2px;
}

.primary-button-wrapper {
  display: inline-block;
  margin-right: 24px;
}

.wellfield-input-container {
  width: 336px;
  margin-bottom: 20px;
}

.link-input-container {
  width: 592px;
}
