.gathering-center-settings-container {
  overflow: unset;
}

.gathering-center-settings-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.gathering-center-row {
  width: 100%;
  height: 40px;
}

.gathering-center-row.select-row {
  height: 64px;
}

.gathering-center-row.displaying-row {
  margin-top: 16px;
}

.gathering-center-column {
  margin-right: 3px;
}

.pressure-column {
  margin-right: 16px;
}

.left {
  float: left;
}

.dash-column {
  margin-right: 3px;
  padding-top: 32px;
}

.dash-column-text {
  color: rgba(15, 46, 74, 0.4);
  font-size: 16px;
  line-height: 21px;
}

.gathering-center-row-btn {
  margin-top: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.gathering-center-row-btn.add-btn {
  color: #006cb1;
}

.gathering-center-row-btn.delete-btn {
  color: #f0115e;
}

.gathering-center-row-btn.add-btn:hover {
  color: mix(#006cb1, rgba(111, 182, 255, 0.48));
}

.gathering-center-row-btn.add-btn:active {
  color: #0857ae;
}

.gathering-center-row-btn.delete-btn:hover {
  color: mix(#f0115e, rgba(255, 205, 222, 0.48));
}

.gathering-center-row-btn.delete-btn:active {
  color: #d5125a;
}

.gathering-center-row.displaying-row .gathering-center-row-btn {
  margin-top: 8px;
}

.gathering-center-row.displaying-row .dash-column {
  padding-top: 8px;
}

.clearfix {
  clear: both;
}

.gathering-centers-dropdown {
  width: 512px;
  margin-right: 16px;
}
