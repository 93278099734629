.left-col {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-flow: column;
  width: 800px;
  height: 100vh;
  overflow: hidden;
  background: #fff;
  border-radius: 2px;
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.04);
}

.left-col__top-row {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 24px 32px 24px 24px;

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: #e9eaee;
    border-radius: 2px;
    content: '';
  }
}

.left-col__top-sub-row {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.left-col__top-row-container {
  position: relative;
  z-index: 1;
}

.left-col__bottom-shadow {
  position: absolute;
  top: 0;
  right: 32px;
  left: 32px;
  z-index: -1;
  height: 32px;
  box-shadow: 0 -4px 24px rgba(23, 27, 61, 0.08);
  opacity: 0;
  transition: all 0.25s ease;
}

.left-col__top-row-container-shadow {
  position: absolute;
  right: 32px;
  bottom: 0;
  left: 32px;
  z-index: -1;
  height: 24px;
  box-shadow: 0 4px 24px rgba(23, 27, 61, 0.08);
  opacity: 0;
  transition: all 0.25s ease;
}

.scrolled {
  .left-col__top-row-container-shadow,
  .left-col__bottom-shadow {
    opacity: 1;
  }
}

.left-col__link {
  display: flex;
  align-items: center;
}

.left-col__link-text {
  margin-left: 12px;
  line-height: 18px;
}

.scrollbar-container div.ps__rail-y {
  display: none;
}

.left-col__main-container {
  margin-right: -17px;
  padding-right: 17px;
  overflow: auto;
}

.left-col__bottom {
  position: relative;
  flex-basis: auto;
  background-color: #fff;
}
