@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.button {
  position: relative;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  transition: all 0.25s ease;

  &.primary {
    color: #fff;
    background: #006cb1;

    &:not(:disabled):hover {
      background: linear-gradient(0deg, rgba(111, 182, 255, 0.48), rgba(111, 182, 255, 0.48)), #006cb1;
    }

    &:not(:disabled):active {
      background: linear-gradient(0deg, rgba(23, 27, 61, 0.24), rgba(23, 27, 61, 0.24)), #006cb1;
    }
  }

  &.secondary {
    color: #006cb1;
    background: rgba(4, 106, 210, 0.12);

    &:not(:disabled):hover {
      background: rgba(4, 106, 210, 0.2);
    }

    &:not(:disabled):active {
      background: rgba(4, 106, 210, 0.24);
    }
  }

  &.execution {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 44px;
    color: #006cb1;
    background: #fff;
    border: 1px solid rgba(4, 106, 210, 0.4);

    &::before {
      position: absolute;
      top: 12px;
      left: 16px;
      width: 16px;
      height: 16px;
      background-image: url(../../assets/images/executionButton.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      animation: spin 4s linear infinite;
      content: '';
    }
  }

  &:disabled {
    color: #c1c2c6;
    background: #f3f4f8;
  }
}
